// src/demo/question_answer.js
export const flowData = [
  {
    id: 1,
    message: "Hi, I am your virtual medical assistant. Which of these interests you?",
    options: [
      "Obtaining a single source of truth for my medical history",
      "Recommendations for a healthy lifestyle",
      "Specific clinical questions",
    ],
    next: 2,
  },
  {
    id: 2,
    message:
      "Great, looking forward to working with you on this journey. To get started, I need to access your medical information. Do you authorize me to view your medical records?",
    options: ["Yes", "No"],
    next: { Yes: 3, No: 4 },
  },
  {
    id: 3,
    message:
      "Ok thanks. Do you have any other information you would like to provide me? Please upload any documents that you have that you think may be useful or provide any additional context.",
    options: [], // This step uses FileUpload and TextInput components
    next: 4,
  },
  {
    id: 4,
    message: "Thanks! I'll start processing your information now. While I work on that, feel free to ask me any questions.",
    options: [],
    next: 5,
  }
];

// Define SUMMARY_STEP_ID
export const SUMMARY_STEP_ID = 10; // Update this based on your flow
