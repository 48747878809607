// src/components/FileUpload.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axiosInstance from '../api/axiosConfig';

const FileUpload = ({ onUpload }) => {
  return (
    <Box
      sx={{
        border: '2px dashed #ccc',
        borderRadius: 2,
        p: 4,
        textAlign: 'center',
      }}
    >
      <CloudUploadIcon fontSize="large" color="action" />
      <Typography variant="body1" gutterBottom>
        Drag and drop files here, or click to select files
      </Typography>
      <input
        type="file"
        multiple
        onChange={onUpload}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        <Button variant="contained" component="span">
          Select Files
        </Button>
      </label>
    </Box>
  );
};

export default FileUpload;
