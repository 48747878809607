import React from 'react';
import { Tooltip, Chip, CircularProgress } from '@mui/material';

const ProcessingTooltip = ({ status }) => {
  return (
    <Tooltip
      title="Reviewing your medical history, this can take up to 5 minutes to complete."
      arrow
      placement="bottom"
      enterDelay={200}
      leaveDelay={200}
      PopperProps={{
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        }],
      }}
    >
      <Chip
        icon={status === 'processing' ? <CircularProgress size={16} /> : null}
        label={status === 'processing' ? 'Processing...' : 'Ready'}
        color={status === 'processing' ? 'default' : 'success'}
        variant="outlined"
        sx={{ 
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: 'white',
          '& .MuiCircularProgress-root': { color: 'white' },
          cursor: 'help'
        }}
      />
    </Tooltip>
  );
};

export default ProcessingTooltip; 