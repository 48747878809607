// src/components/Summary.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Summary = ({ responses, onDownload }) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Your Medical Summary
      </Typography>
      <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 2 }}>
        <Typography variant="body1" component="pre">
          {JSON.stringify(responses, null, 2)}
        </Typography>
      </Box>
      <Button variant="contained" sx={{ mt: 2 }} onClick={onDownload}>
        Download PDF
      </Button>
    </Box>
  );
};

export default Summary;
