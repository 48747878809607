// src/components/AuthScreen.js

import React, { useState } from 'react';
import axiosInstance from '../api/axiosConfig';
import { Box, Button, TextField, Typography, Container, Paper, Link, CircularProgress } from '@mui/material';
import logo from '../assets/orin_logo.png';

function AuthScreen({ onLogin }) {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (isForgotPassword) {
        if (resetToken) {
          // Reset password
          await axiosInstance.post('/auth/reset-password', { token: resetToken, new_password: newPassword });
          alert('Password reset successful. Please login with your new password.');
          setIsForgotPassword(false);
        } else {
          // Request password reset
          await axiosInstance.post('/auth/forgot-password', { email });
          alert('If an account with that email exists, a password reset link has been sent.');
          setResetToken('');
        }
      } else if (isLogin) {
        const response = await axiosInstance.post('/auth/login', new URLSearchParams({
          username: email,
          password: password
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        onLogin(response.data.access_token, email); // Pass both token and email
      } else {
        // Create account
        await axiosInstance.post('/auth/register', { email, password });
        // After successful registration, log the user in
        const loginResponse = await axiosInstance.post('/auth/login', new URLSearchParams({
          username: email,
          password: password
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        onLogin(loginResponse.data.access_token, email); // Pass both token and email
      }
    } catch (error) {
      console.error('Authentication error:', error);
      alert(error.response?.data?.detail || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          component="img"
          src={logo}
          alt="Logo"
          sx={{ width: 100, height: 100, mb: 2 }}
        />
        <Typography component="h1" variant="h5">
          {isForgotPassword ? 'Reset Password' : (isLogin ? 'Login' : 'Create Account')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          {!isForgotPassword && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          )}
          {isForgotPassword && (
            <>
              {!resetToken ? (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="resetToken"
                    label="Reset Token"
                    name="resetToken"
                    value={resetToken}
                    onChange={(e) => setResetToken(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="newPassword"
                    label="New Password"
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </>
              )}
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            {loading ? 'Processing...' : (isForgotPassword ? (resetToken ? 'Reset Password' : 'Send Reset Link') : (isLogin ? 'Login' : 'Create Account'))}
          </Button>
          {!isForgotPassword && (
            <Button
              fullWidth
              variant="text"
              onClick={() => setIsLogin(!isLogin)}
              disabled={loading}
            >
              {isLogin ? 'Need to create an account?' : 'Already have an account?'}
            </Button>
          )}
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                setIsForgotPassword(!isForgotPassword);
                setResetToken('');
                setNewPassword('');
              }}
              disabled={loading}
            >
              {isForgotPassword ? 'Back to Login' : 'Forgot Password?'}
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default AuthScreen;
