// src/App.js (Relevant Snippet)

import React, { useState, useEffect } from 'react';
import axiosInstance from './api/axiosConfig';
import { flowData, SUMMARY_STEP_ID } from './demo/question_answer';
import Message from './components/Message';
import Options from './components/Options';
import FileUpload from './components/FileUpload';
import TextInput from './components/TextInput';
import NotificationChoice from './components/NotificationChoice';
import Summary from './components/Summary';
import AuthScreen from './components/AuthScreen';
import ProcessingCompletedModal from './components/ProcessingCompletedModal'; // Import the modal
import QuestionModal from './components/QuestionModal'; // Import the modal
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  CssBaseline,
  Typography,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  CircularProgress,
  Chip,
  Button,
} from '@mui/material';
import jsPDF from 'jspdf';
import logo from './assets/orin_logo.png';
import Chat from './components/Chat'; // To be implemented in Step 5
import ProcessingTooltip from './components/ProcessingTooltip';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
  const [currentStepId, setCurrentStepId] = useState(1);
  const [responses, setResponses] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [tabIndex, setTabIndex] = useState(0); // For Tabs
  const [anchorEl, setAnchorEl] = useState(null); // For Menu
  const open = Boolean(anchorEl);
  const userInitial = userEmail ? userEmail.charAt(0).toUpperCase() : 'U';
  const [processingStatus, setProcessingStatus] = useState('processing');
  const currentStep = flowData.find((step) => step.id === currentStepId);
  
  // New state for the modal and flow
  const [modalOpen, setModalOpen] = useState(false);
  const [clarificationFlow, setClarificationFlow] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [chatInitiated, setChatInitiated] = useState(false);
  const [profileReady, setProfileReady] = useState(false);
  const [clarifications, setClarifications] = useState({});
  const [resummarizing, setResummarizing] = useState(false); // New state

  const [processingComplete, setProcessingComplete] = useState(false);
  const [qaComplete, setQaComplete] = useState(false);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('userEmail', userEmail);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('userEmail');
    }
  }, [token, userEmail]);

  useEffect(() => {
    if (token) {
      const fetchProcessingStatus = async () => {
        try {
          const response = await axiosInstance.get('/users/me/status');
          setProcessingStatus(response.data.processing_status);
          if (response.data.processing_status === 'completed') {
            handleProcessingComplete();
            setModalOpen(true);
          }
        } catch (error) {
          console.error('Error fetching processing status:', error);
          // Keep processing status as is instead of defaulting to ready
          if (!error.response || error.response.status !== 404) {
            // Only log other types of errors
            console.error('Unexpected error checking processing status:', error);
          }
        }
      };

      fetchProcessingStatus();

      const intervalId = setInterval(fetchProcessingStatus, 60000);

      return () => clearInterval(intervalId);
    }
  }, [token]);

  const handleProceed = () => {
    if (processingComplete) {
      setModalOpen(false);
      setClarificationFlow(true);
    } else {
      console.warn('Attempted to proceed before processing was complete');
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleClarificationsClose = (answers) => {
    setClarifications(answers);
    setClarificationFlow(false);
    setQaComplete(true);
  };

  const pollForUpdatedSummary = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axiosInstance.get('/summaries/latest');
        if (response.data && response.data.level >= 3) {
          // Assuming level 3 indicates the new resummarized data
          setResummarizing(false); // Stop resummarization
          setProfileReady(true);
          clearInterval(intervalId); // Stop polling
        }
      } catch (error) {
        console.error('Error fetching latest summary:', error);
      }
    }, 5000); // Poll every 5 seconds
  };

  const handleLogin = (newToken, email) => {
    setToken(newToken);
    setUserEmail(email);
  };

  const handleLogout = () => {
    setToken(null);
    setUserEmail('');
    setCurrentStepId(1);
    setResponses({});
    setUploadedFiles([]);
  };

  const handleOptionSelect = (option) => {
    setResponses({ ...responses, [currentStepId]: option });
    const nextStep =
      typeof currentStep.next === 'object'
        ? currentStep.next[option]
        : currentStep.next;
    setCurrentStepId(nextStep);
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles([...uploadedFiles, ...files]);
    setResponses({ ...responses, uploadedFiles: [...uploadedFiles, ...files] });
    setCurrentStepId(currentStep.next);
  };

  const handleTextSubmit = (text) => {
    setResponses({ ...responses, additionalContext: text });
    setCurrentStepId(currentStep.next);
  };

  const handleContactSubmit = (contact) => {
    setResponses({ ...responses, contact });
    setCurrentStepId(currentStep.next);
  };

  const handleDownload = () => {
    const doc = new jsPDF();
    let yOffset = 10;

    Object.entries(responses).forEach(([key, value]) => {
      let text = '';

      if (Array.isArray(value)) {
        text = `${key}: ${value.map((file) => file.name).join(', ')}`;
      } else {
        text = `${key}: ${value}`;
      }

      doc.text(text, 10, yOffset);
      yOffset += 10;

      if (yOffset > 280) {
        doc.addPage();
        yOffset = 10;
      }
    });

    doc.save('medical-summary.pdf');
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#003732',
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#e5bf94',
            '&.Mui-disabled': {
              color: 'rgba(229, 191, 148, 0.7)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 25,
            textTransform: 'none',
            padding: '8px 24px',
            fontSize: '1rem',
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            },
          },
          outlined: {
            borderRadius: 25,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 25,
          },
        },
      },
    },
  });

  const handleChatStart = () => {
    setChatInitiated(true);
  };

  useEffect(() => {
    if (profileReady && !chatInitiated) {
      setChatInitiated(true);
    }
  }, [profileReady, chatInitiated]);

  useEffect(() => {
    if (processingComplete && qaComplete) {
      pollForUpdatedSummary();
    }
  }, [processingComplete, qaComplete]);

  const handleProcessingComplete = () => {
    setProcessingComplete(true);
  };

  const handleQAComplete = () => {
    setQaComplete(true);
  };

  const handleQuestionModalClose = (answers) => {
    setClarifications(answers);
    setClarificationFlow(false);
    handleQAComplete();
  };

  if (!currentStep) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm">
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" color="error">
              An error occurred. Please try again later.
            </Typography>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!token ? (
        <AuthScreen onLogin={handleLogin} />
      ) : (
        <>
          <AppBar position="static" role="banner"> {/* Explicitly set role="banner" */}
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <Box
                  component="img"
                  src={logo}
                  alt="Logo"
                  sx={{ width: 100, height: 100 }}
                />
                <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)} aria-label="navigation tabs">
                  <Tab label="My Data" />
                  <Tab label="My Advisor" disabled />
                  <Tab label="My Services" disabled />
                </Tabs>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <ProcessingTooltip status={processingStatus} />
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="user account"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  color="inherit"
                  data-testid="user-avatar-button"
                >
                  <Avatar>{userInitial}</Avatar>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => {
                    setAnchorEl(null);
                    handleDownload();
                  }}>Download my health profile</MenuItem>
                  <MenuItem onClick={() => {
                    setAnchorEl(null);
                    handleLogout();
                  }}>Logout</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm">
            <Box sx={{ mt: 4 }}>
              {tabIndex === 0 && (
                <>
                  <Message text={currentStep.message} />
                  {currentStep.options.length > 0 && (
                    <Options options={currentStep.options} onSelect={handleOptionSelect} />
                  )}
                  {currentStepId === 3 && (
                    <>
                      <FileUpload onUpload={handleFileUpload} />
                      <TextInput onSubmit={handleTextSubmit} />
                    </>
                  )}
                  {currentStepId === 4 && (
                    <>
                      <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography>
                          I'm processing your information. This may take a few minutes. Feel free to chat with me while you wait.
                        </Typography>
                      </Box>
                      <Chat onChatStart={handleChatStart} />
                    </>
                  )}
                  {currentStepId === SUMMARY_STEP_ID && (
                    <Summary responses={responses} onDownload={handleDownload} />
                  )}
                  {resummarizing && (
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                      <CircularProgress />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Updating your profile with your responses. This may take a few moments...
                      </Typography>
                    </Box>
                  )}
                  {profileReady && (
                    <Box sx={{ mt: 2, p: 2, bgcolor: 'success.light', borderRadius: 1 }}>
                      <Typography variant="h6">Your Health Profile is ready!</Typography>
                      <Button variant="contained" color="primary" onClick={handleDownload} sx={{ mt: 1 }}>
                        Download Health Profile
                      </Button>
                    </Box>
                  )}
                </>
              )}
              {tabIndex === 1 && (
                <Typography variant="h6">My Advisor (Coming Soon)</Typography>
              )}
              {tabIndex === 2 && (
                <Typography variant="h6">My Services (Coming Soon)</Typography>
              )}
            </Box>
          </Container>

          {/* Processing Completed Modal */}
          <ProcessingCompletedModal 
            open={modalOpen} 
            onClose={handleModalClose} 
            onProceed={handleProceed} 
          />

          {/* Clarification Q&A Modal */}
          {clarificationFlow && (
            <QuestionModal 
              open={clarificationFlow} 
              onClose={() => setClarificationFlow(false)} 
              onProceed={handleQuestionModalClose}
            />
          )}
        </>
      )}
    </ThemeProvider>
  );
}

export default App;