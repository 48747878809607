// src/components/Message.js
import React from 'react';
import { Typography } from '@mui/material';

const Message = ({ text }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {text}
    </Typography>
  );
};

export default Message;
