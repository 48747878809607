// src/components/Options.js
import React from 'react';
import { Button, Stack } from '@mui/material';

const Options = ({ options, onSelect }) => {
  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      {options.map((option, index) => (
        <Button
          key={index}
          variant="contained"
          fullWidth
          onClick={() => onSelect(option)}
          sx={{ textTransform: 'none' }}
        >
          {option}
        </Button>
      ))}
    </Stack>
  );
};

export default Options;
