import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Box,
} from '@mui/material';
import axiosInstance from '../api/axiosConfig';

const QuestionModal = ({ open, onClose, onProceed }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (open) {
      const fetchQuestions = async () => {
        setLoading(true);
        setError('');
        try {
          // Try to start a new Q&A session
          const sessionResponse = await axiosInstance.post('/questions/start-qa');
          
          if (sessionResponse.status === 400) {
            // Session already completed or active
            setError(sessionResponse.data.detail);
            onClose();
            return;
          }

          const sessionId = sessionResponse.data.id;
          const questionsResponse = await axiosInstance.get(`/questions/sessions/${sessionId}/questions`);
          
          // Filter out any questions that already have answers
          const unansweredQuestions = questionsResponse.data.filter(q => !q.answers?.length);
          
          if (unansweredQuestions.length === 0) {
            setError('All questions have been answered');
            onClose();
            return;
          }

          setQuestions(unansweredQuestions);
        } catch (error) {
          console.error('Error fetching questions:', error);
          setError('Failed to load questions. Please try again.');
          onClose();
        } finally {
          setLoading(false);
        }
      };
      fetchQuestions();
    }
  }, [open, onClose]);

  const handleNext = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    setSubmitting(true);
    try {
      // Submit the answer to the backend
      await axiosInstance.post(
        `/questions/${currentQuestion.id}/answer`,
        { text: currentAnswer }
      );
      setAnswers([...answers, currentAnswer]);
      setCurrentAnswer('');

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        onProceed(answers.concat(currentAnswer));
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
      setError('Failed to submit answer. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    onProceed(answers);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Clarifications Needed</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" alignItems="center">
            <CircularProgress size={24} />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Loading questions...
            </Typography>
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : questions.length > 0 ? (
          <>
            <Typography variant="h6" gutterBottom>
              Question {currentQuestionIndex + 1} of {questions.length}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {questions[currentQuestionIndex].text}
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label="Your Answer"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              minRows={3}
              value={currentAnswer}
              onChange={(e) => setCurrentAnswer(e.target.value)}
            />
          </>
        ) : (
          <Typography>No questions available.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
        <Button
          onClick={handleNext}
          disabled={!currentAnswer.trim() || submitting || loading}
          variant="contained"
        >
          {submitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : currentQuestionIndex < questions.length - 1 ? 'Next' : 'Finish'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionModal; 