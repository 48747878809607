// src/components/TextInput.js
import React from 'react';
import { TextField, Button, Box } from '@mui/material';

const TextInput = ({ onSubmit }) => {
  const [input, setInput] = React.useState('');

  const handleSubmit = () => {
    onSubmit(input);
    setInput('');
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        label="Additional Context"
        multiline
        rows={4}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        variant="outlined"
        fullWidth
      />
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default TextInput;
