import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const ProcessingCompletedModal = ({ open, onClose, onProceed }) => {
  const handleProceed = () => {
    onProceed();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Processing Complete</DialogTitle>
      <DialogContent>
        <Typography>
          We have finished processing your medical data. We have a few questions to better personalize your profile.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProceed} variant="contained">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessingCompletedModal; 